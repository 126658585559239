import Hero from "../../components/Hero";
import AboutResto from "./components/Resto";
import { useEffect } from "react";
import { useFirebase, useLanguage } from "../../hooks/useContexts";

export default function RestaurantPage() {
  const { languageData } = useLanguage();
  const { analytics, logEvent } = useFirebase();

  useEffect(() => {
    document.title = languageData?.restaurant?.metadata?.pageTitle;
    logEvent(analytics, "page_view", {
      page_title: languageData?.restaurant?.metadata?.pageTitle,
      page_path: languageData?.restaurant?.metadata?.path,
      page_location: window.location.host
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Hero imgUrl={'https://firebasestorage.googleapis.com/v0/b/manantial-del-silencio.firebasestorage.app/o/public%2Fpages%2Frestaurant%2Fhero%2Frestomain.jpg?alt=media&token=8f35c691-8c19-4b39-8165-454e2896c383'} hideTitle={true} />
      <AboutResto isCardInfo={true} />
    </>
  );
}
