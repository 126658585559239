import Hero from "../../components/Hero";
import React, { useEffect } from "react";
import SliderEvent from "./components/SliderEvent";
import { useFirebase, useLanguage } from "../../hooks/useContexts";
import Footer from "../../components/Footer";

export default function Events() {
    const { languageData } = useLanguage();
    const { analytics, logEvent } = useFirebase();

    useEffect(() => {
        document.title = languageData?.events?.metadata?.pageTitle;
        logEvent(analytics, "page_view", {
            page_title: languageData?.events?.metadata?.pageTitle,
            page_path: languageData?.events?.metadata?.path,
            page_location: window.location.host
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Hero imgUrl={'https://firebasestorage.googleapis.com/v0/b/manantial-del-silencio.firebasestorage.app/o/public%2Fpages%2Fevents%2Fhero%2Feventoinfo.webp?alt=media&token=91f36f58-4c75-40ac-ae6a-b13b07b0291d'} hideTitle={true} />
            <SliderEvent />
            <Footer />
        </>
    );
}
