
import DetailRoom from "../Room/components/DetailRoom";
import Hero from "../../components/Hero";
import { useFirebase, useLanguage } from "../../hooks/useContexts";
import suite from "../../assets/suiteheader.jpg"
import { useEffect } from "react";

export default function SuiteRoom() {
    const title = 'Suite Room';
    const { languageData } = useLanguage()
    const { analytics, logEvent } = useFirebase();
    useEffect(() => {
        document.title = "Suite Room - El Manantial del Silencio";
        logEvent(analytics, "page_view", {
            page_title: "Suite Room - El Manantial del Silencio",
            page_path: window.location.pathname,
            page_location: window.location.host
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Hero imgUrl={suite} returnPage={languageData?.routes.rooms} hideTitle={true} />
            <DetailRoom title={title} />
        </>
    )
}
