import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../hooks/useContexts';
import { useIsWeb } from '../../../hooks/isWeb';
import { useFirebase } from '../../../hooks/useContexts';

const boda = "https://firebasestorage.googleapis.com/v0/b/manantial-del-silencio.firebasestorage.app/o/public%2Fpages%2Fevents%2Fslider%2Fboda%2Fboda.jpg?alt=media&token=03eea7d0-2f77-4fc3-85cc-5794b1626baf";
const boda1 = "https://firebasestorage.googleapis.com/v0/b/manantial-del-silencio.firebasestorage.app/o/public%2Fpages%2Fevents%2Fslider%2Fboda%2Fboda1.jpg?alt=media&token=9161a738-d824-4065-aa0f-a59d00e295b0";
const boda2 = "https://firebasestorage.googleapis.com/v0/b/manantial-del-silencio.firebasestorage.app/o/public%2Fpages%2Fevents%2Fslider%2Fboda%2Fboda2.jpg?alt=media&token=0a15610a-312a-4f6a-894b-f74b268e1bb2";
const boda3 = "https://firebasestorage.googleapis.com/v0/b/manantial-del-silencio.firebasestorage.app/o/public%2Fpages%2Fevents%2Fslider%2Fboda%2Fboda3.jpg?alt=media&token=9eed20c6-8650-49bf-afe5-06e0b616e7bb";
const boda4 = "https://firebasestorage.googleapis.com/v0/b/manantial-del-silencio.firebasestorage.app/o/public%2Fpages%2Fevents%2Fslider%2Fboda%2Fboda4.jpg?alt=media&token=1dd7f4cd-2961-4e88-989c-59a5467e7bc0";
const boda5 = "https://firebasestorage.googleapis.com/v0/b/manantial-del-silencio.firebasestorage.app/o/public%2Fpages%2Fevents%2Fslider%2Fboda%2Fboda5.jpg?alt=media&token=4d8533d6-5c4e-4e19-9b6d-ec43d69434bc";

const event = "https://firebasestorage.googleapis.com/v0/b/manantial-del-silencio.firebasestorage.app/o/public%2Fpages%2Fevents%2Fslider%2Fevento%2Feventos.jpg?alt=media&token=d78238db-61c8-4cb5-bbb5-0d1e3cb2ae06";
const event1 = "https://firebasestorage.googleapis.com/v0/b/manantial-del-silencio.firebasestorage.app/o/public%2Fpages%2Fevents%2Fslider%2Fevento%2Feventos1.jpg?alt=media&token=f6b3f7b2-7abc-417b-b527-0c3ae4b01405";

export default function SliderEvent() {
  const { languageData } = useLanguage();
  const isWeb = useIsWeb();
  const { analytics, logEvent } = useFirebase();

  useEffect(() => {
    if (analytics) {
      logEvent(analytics, "view_slider_event", {
        section: "Eventos Slider",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const services = [
    {
      imgs: [boda, boda1, boda2, boda3, boda4, boda5],
      type: languageData?.events?.data?.wedding,
      urlPage: languageData?.routes?.singleRoom,
      detail: languageData?.events?.data?.weddingInfo
    },
    {
      imgs: [event, event1, event, event1, event, event1],
      type: languageData?.events?.data?.guest,
      urlPage: languageData?.routes?.doubleRoom,
      detail: languageData?.events?.data?.guestInfo
    }
  ];

  return (
    <section>
      <div className="absolute bg-crema left-0 w-full flex justify-center items-center">
        <div className={`z-50 rounded-lg bg-crema flex flex-row ${isWeb ? "mt-[-5%]" : "mt-[-40%]"} bg-crema text-center`}>
          <div className="container rounded bg-white max-w-[1200px] mx-auto py-16">
            <h2 className="font-libre-baskerville italic text-3xl sm:text-[46px] tracking-[.04em] text-vino text-center mb-3">
              {languageData?.events?.data?.title}
            </h2>
            <p className="px-6 font-light text-base sm:text-lg font-mont">
              {languageData?.events?.data?.subtitle}
            </p>
            <div className="grid lg:grid-cols-2 font-mont py-8 px-8">
              {services.map((service, index) => (
                <CarouselCard key={index} service={service} analytics={analytics} logEvent={logEvent} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const CarouselCard = ({ service, analytics, logEvent }) => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => {
        const nextImage = (prevImage + 1) % service.imgs.length;
        if (analytics) {
          logEvent(analytics, "change_slider_image", {
            section: "Eventos Slider",
            image_index: nextImage,
            service_type: service.type,
          });
        }
        return nextImage;
      });
    }, 3000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service.imgs.length, service.type]);

  return (
    <div className="container-card-room sm:p-2 pb-4">
      <div className="relative w-full h-80 rounded-lg mb-4">
        {service.imgs.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`${service.type} ${index + 1}`}
            className={`absolute w-full h-[400px] object-cover transition-opacity duration-1000 ${index === currentImage ? 'opacity-100' : 'opacity-0'}`}
            onClick={() => {
              if (analytics) {
                logEvent(analytics, "click_slider_interaction", {
                  section: "Eventos Slider",
                  image_index: index,
                  service_type: service.type,
                });
              }
            }}
          />
        ))}
      </div>
      <div className="relative w-4/5 ml-[10%] z-50 bg-white p-4 shadow-md text-center">
        <h3 className="text-vino text-lg font-mont">{service.type}</h3>
        <p className="text-gray-600 font-mont">{service.detail}</p>
      </div>
    </div>
  );
};
