
import DetailRoom from "../Room/components/DetailRoom";
import Hero from "../../components/Hero";
import { useFirebase, useLanguage } from "../../hooks/useContexts";
import simple from "../../assets/single.webp";
import { useEffect } from "react";
export default function SingleRoom() {
  const { languageData } = useLanguage()
  const { analytics, logEvent } = useFirebase();
  const title = ' Twin Room';
  useEffect(() => {
    document.title = "Twin Room - El Manantial del Silencio";
    logEvent(analytics, "page_view", {
      page_title: "Twin Room - El Manantial del Silencio",
      page_path: window.location.pathname,
      page_location: window.location.host
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Hero imgUrl={simple} returnPage={languageData?.routes.rooms} hideTitle={true} />
      <DetailRoom title={title} />
    </>

  )
}

