
import Hero from '../../components/Hero'
import About from './components/About';
import SpaCard from './components/SpaCard'
import Gmap from './components/Gmap'
import Footer from '../../components/Footer'
import RoomCard from './components/RoomCard';
import RestaurantCard from './components/RestaurantCard';
import EventsBanner from './components/EventsBanner'
import { useFirebase, useLanguage } from '../../hooks/useContexts';
import { useEffect } from 'react';

export default function Home() {
    const { languageData } = useLanguage();
    const { analytics, logEvent } = useFirebase();

    useEffect(() => {
        document.title = languageData?.home?.metadata?.pageTitle;
        logEvent(analytics, "page_view", {
            page_title: languageData?.home?.metadata?.pageTitle,
            page_path: languageData?.home?.metadata?.path,
            page_location: window.location.host
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Hero />
            <About />
            <RoomCard />
            <RestaurantCard />
            <SpaCard />
            <EventsBanner />
            <Gmap />

            <Footer />
        </>
    )
}