import React, { useEffect, useState } from 'react';
import { useLanguage, useFirebase } from '../../../hooks/useContexts';
import { useIsWeb } from '../../../hooks/isWeb';

export default function About() {
  const { analytics, logEvent } = useFirebase();
  const { languageData } = useLanguage();

  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [numberOfPeople, setNumberOfPeople] = useState("");

  let isWeb = useIsWeb();
  const [isScroll, setIsScroll] = useState(false);
  const startTime = performance.now();

  const handleSubmit = (e) => {
    e.preventDefault();

    const endTime = performance.now();
    const timeToSubmit = Math.round(endTime - startTime); // Milisegundos

    if (analytics) {
      logEvent(analytics, "reservation_request", {
        button_name: "Enviar reserva",
        check_in_date: checkInDate,
        check_out_date: checkOutDate,
        number_of_people: numberOfPeople,
        time_to_submit_ms: timeToSubmit,
      });
    }

    const message = `Reserva: \nFecha de entrada: ${checkInDate} \nFecha de salida: ${checkOutDate}\nNúmero de personas: ${numberOfPeople}`;
    const phoneNumber = '+5493884841382';
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    window.open(whatsappURL, '_blank');

    setCheckInDate("");
    setCheckOutDate("");
    setNumberOfPeople("");
  };





  useEffect(() => {
    const controller = new AbortController();
    function handleScroll() {
      setIsScroll(window.scrollY > 0);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      controller.abort();
    };
  }, []);

  return (
    <section className='bg-crema min-h-[300px] mx-auto px-3 flex items-center'>
      {/* Reservas */}

      {!isWeb && isScroll && (
        <div className="absolute left-0 w-full flex justify-center items-center">
          <div className={`z-50 rounded-lg bg-white flex flex-row md:mt[-20%] sm:mt-[-120%] ${isWeb ? "" : "mt-[-115%]"} bg-crema text-center`}>
            <form onSubmit={handleSubmit} className="bg-white p-4 rounded-lg shadow-md flex flex-wrap items-center">
              <div className="flex-grow">
                <label htmlFor="checkInDate" className="block text-gray-700 font-bold mb-2">
                  {languageData?.room?.data?.entryData}
                </label>
                <input
                  type="date"
                  id="checkInDate"
                  value={checkInDate}
                  onChange={(e) => setCheckInDate(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="flex-grow">
                <label htmlFor="checkOutDate" className="block text-gray-700 font-bold mb-2">
                  {languageData?.room?.data?.departureDate}
                </label>
                <input
                  type="date"
                  id="checkOutDate"
                  value={checkOutDate}
                  onChange={(e) => setCheckOutDate(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="flex-grow">
                <label htmlFor="numberOfPeople" className="block text-gray-700 font-bold mb-2">
                  {languageData?.room?.data?.amountOfPeople}
                </label>
                <input
                  type="number"
                  id="numberOfPeople"
                  value={numberOfPeople}
                  onChange={(e) => setNumberOfPeople(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="flex-grow">
                <button type="submit" className="rounded-sm ml-2 p-2 mt-7 pb-2 text-center justify-center items-center font-libre-baskerville bg-vino border-white border-1 sticky text-white">
                  {languageData?.room?.data?.booking}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {isWeb && (
        <div className="absolute left-0 w-full flex justify-center items-center">
          <div className={`z-50 rounded-lg bg-white flex flex-row ${isWeb ? "mt-[-20%]" : "mt-[-120%]"} bg-crema text-center`}>
            <form onSubmit={handleSubmit} className="bg-white p-4 rounded-lg shadow-md flex flex-wrap items-center">
              <div className="flex-grow">
                <label htmlFor="checkInDate" className="block text-gray-700 font-bold mb-2">
                  {languageData?.room?.data?.entryData}
                </label>
                <input
                  type="date"
                  id="checkInDate"
                  value={checkInDate}
                  onChange={(e) => setCheckInDate(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="flex-grow">
                <label htmlFor="checkOutDate" className="block text-gray-700 font-bold mb-2">
                  {languageData?.room?.data?.departureDate}
                </label>
                <input
                  type="date"
                  id="checkOutDate"
                  value={checkOutDate}
                  onChange={(e) => setCheckOutDate(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="flex-grow">
                <label htmlFor="numberOfPeople" className="block text-gray-700 font-bold mb-2">
                  {languageData?.room?.data?.amountOfPeople}
                </label>
                <input
                  type="number"
                  id="numberOfPeople"
                  value={numberOfPeople}
                  onChange={(e) => setNumberOfPeople(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="flex-grow">
                <button type="submit" className="rounded-sm ml-2 p-2 mt-7 pb-2 text-center justify-center items-center font-libre-baskerville bg-vino border-white border-1 sticky text-white">
                  {languageData?.room?.data?.booking}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className='container  max-w-[1100px] mx-auto'>
        <div className='justify-center  items-center text-start'>
          <h3 className='font-bold sm:mt-24 opacity-70 tracking-[0.25em] font-mont text-xl sm:text-[22px] text-vino uppercase'>
            {languageData?.home?.about?.welcomeTo}
          </h3>
          <h1 className='font-libre-baskerville italic text-2xl sm:text-[38px] tracking-[.04em] py-3 text-vino mb-3'>{languageData?.home?.about?.nameHotel}</h1>
          <h2 className='font-mont text-justify text-base sm:text-lg text-vino'>
            {languageData?.home?.about?.descriptionHotel}
          </h2>
        </div>
      </div>
    </section>
  );
}
