import { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";
import { FirebaseContext } from "../context/FirebaseContext";


export function useLanguage() {
    return useContext(LanguageContext);
}

export function useFirebase() {
    return useContext(FirebaseContext);
}