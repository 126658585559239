
import { useFirebase, useLanguage } from "../../../hooks/useContexts";
import single from '../../../assets/Spa.jpg'
import { useEffect } from "react";

export default function SpaCard() {
  const { languageData } = useLanguage()
  const { analytics, logEvent } = useFirebase();
  useEffect(() => {
    if (analytics) {
      logEvent(analytics, "view_spa_section", {
        section: "Spa Card",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-crema">
      <div className="flex justify-center items-center bg-crema p-8">
        <div className="flex flex-col md:flex-row bg-white py-5 px-5 rounded items-start md:items-center w-full max-w-6xl">

          {/* Imagen del Spa */}
          <div className="w-full md:w-1/2 order-1 md:order-1 mt-6 md:mt-0">
            <div className="relative">
              <img
                src={single}
                alt="Spa en Purmamarca"
                className="rounded-lg shadow-lg"
              />
            </div>
          </div>

          {/* Información del Spa */}
          <div className="w-full md:w-1/2 order-2 md:order-1 px-4">
            <h2 className="text-4xl font-libre-baskerville italic text-vino mb-2 sm:mx-4 pt-4">
              {languageData?.home?.cards?.spa?.title}
            </h2>
            <p className="text-xl font-mont text-zinc-500 uppercase mb-4 sm:mx-4">
              {languageData?.home?.cards?.spa?.subTitle}
            </p>
            <p className="text-zinc-700 mb-6 font-mont sm:mx-4">
              {languageData?.home?.cards?.spa?.text}
            </p>
            <a
              href={languageData?.routes.spa}
              className="font-semibold uppercase mt-2 sm:mx-4"
              onClick={() => {
                if (analytics) {
                  logEvent(analytics, "click_see_more", {
                    section: "Spa Card",
                    button_text: languageData?.home?.cards?.spa?.seeMore,
                  });
                }
              }}
            >
              {languageData?.home?.cards?.spa?.seeMore}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};