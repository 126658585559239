import React, { createContext } from 'react'
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

export const FirebaseContext = createContext()

const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_CONFIG_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN}`,
    projectId: `${process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_FIREBASE_CONFIG_APP_ID}`,
    measurementId: `${process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENT_ID}`,
};

const FirebaseProvider = ({ children }) => {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    const contextValue = {
        app,
        analytics,
        logEvent
    }

    return (
        <FirebaseContext.Provider value={contextValue}>
            {children}
        </FirebaseContext.Provider>
    )
}

export default FirebaseProvider
