import React from 'react'
import evento from '../../../assets/evento1.jpg'
import EventsForm from './EventsForm'

export default function EventsBanner() {
  return (
    <div className="relative">
      <img src={evento} alt="Imagen grande" className="w-full h-[650px]" />
      <div className="absolute top-10 sm:right-10 p-4 mx-4 bg-white shadow-md">
        <EventsForm />
      </div>
    </div>
  )
}