import React from "react";
import { useIsWeb } from "../../../hooks/isWeb";
import { useLanguage } from "../../../hooks/useContexts";

export default function Rooms() {
  let isWeb = useIsWeb();
  const { languageData } = useLanguage()
  const room = [
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/manantial-del-silencio.firebasestorage.app/o/public%2Fpages%2Frooms%2Fsingle.webp?alt=media&token=b46c5a25-aea0-4f2e-9769-2e2d303efc21',
      type: "Twin room",
      urlPage: languageData?.routes.singleRoom,
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/manantial-del-silencio.firebasestorage.app/o/public%2Fpages%2Frooms%2Fdoble.webp?alt=media&token=dcd1d724-9319-40e6-8c4a-08eb2b69fcb0',
      type: "Double room",
      urlPage: languageData?.routes.doubleRoom,
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/manantial-del-silencio.firebasestorage.app/o/public%2Fpages%2Frooms%2Fsuite.webp?alt=media&token=94557987-1254-4cf3-8f52-520d1df08862',
      type: "Suite room",
      urlPage: languageData?.routes.suiteRoom,
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/manantial-del-silencio.firebasestorage.app/o/public%2Fpages%2Frooms%2Fcasacard.jpg?alt=media&token=9115a270-5b35-4380-ae92-e9dc768fc0a1',
      type: "House",
      urlPage: languageData?.routes.ejectSuiteRoom,
    },
  ];

  return (
    <section >
      <div className="absolute  bg-crema  left-0 w-full  flex justify-center items-center ">
        <div
          className={`z-50 rounded-lg  bg-crema flex flex-row ${isWeb ? "mt-[-5%]" : "mt-[-40%]"} bg-crema text-center`}
        >
          <div className="container rounded bg-white max-w-[1200px] mx-auto py-16">
            <h2 className="font-libre-baskerville italic text-3xl sm:text-[46px] tracking-[.04em] text-vino text-center  mb-3">
              {languageData?.room?.data?.title}
            </h2>
            <div className="flex items-center justify-center"></div>

            <div className="grid  lg:grid-cols-2 ">
              {room.map((rooms, index) => {
                return (
                  <div
                    className="container-card-room "
                    key={index}
                    style={{ padding: "25px" }}
                  >
                    <div className="mx-2 mb-2 object-cover relative after:absolute cursor-pointer after:content-[''] after:top-0 after:left-0 after:w-full after:h-full after:bg-gradient-to-b from-black/25 to-black/75">
                      <img src={rooms.img} alt="" style={{ zIndex: 2 }} />
                    </div>

                    <div className="relative cursor-pointer top-[-10%] w-4/5 ml-[10%] z-50 bg-white p-4 shadow-md text-center">
                      <h3 className="text-gray-800 text-lg font-semibold">
                        {rooms.type}
                      </h3>
                      <button className="mt-2 bg-vino text-white  uppercase px-4 py-2 rounded-md hover:bg-vino-dark">
                        <a href={rooms.urlPage}> {languageData?.room?.data?.seeDetail} </a>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>


    </section>
  );
}
