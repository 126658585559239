import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../../hooks/useContexts';
import { useIsWeb } from '../../../hooks/isWeb';

import massages1 from "../../../assets/spa/masajes/spa1.jpg";
import massages2 from "../../../assets/spa/masajes/spa2.jpg";
import massages3 from "../../../assets/spa/masajes/spa3.jpg";

import spa1 from "../../../assets/spa/recorridoSpa/spa.jpg";
import spa2 from "../../../assets/spa/recorridoSpa/spa1.jpg";
import spa3 from "../../../assets/spa/recorridoSpa/spa3.jpg";

export default function Spa() {
  const { languageData } = useLanguage();
  const isWeb = useIsWeb();

  const services = [
    {
      imgs: [massages1, massages2, massages3],
      type: languageData?.spa?.card?.mass,
      urlPage: languageData?.routes?.singleRoom,
      detail: languageData?.spa?.card?.detailMass
    },
    {
      imgs: [spa1, spa2, spa3],
      type: languageData?.spa?.card?.spa,
      urlPage: languageData?.routes.doubleRoom,
      detail: languageData?.spa?.card?.detailSpa
    }
  ];

  return (
    <section>
      <div className="absolute bg-crema left-0 w-full flex justify-center items-center">
        <div className={`z-50 rounded-lg bg-crema flex flex-row ${isWeb ? "mt-[-5%]" : "mt-[-40%]"} bg-crema text-center`}>
          <div className="container rounded bg-white max-w-[1200px] mx-auto py-16">
            <h2 className="font-libre-baskerville italic text-3xl sm:text-[46px] tracking-[.04em] text-vino text-center mb-3 py-4">{languageData.spa?.card?.title}</h2>
            <p className="px-8  font-mont text-base sm:text-lg text-start  ">
              {languageData.spa?.card?.subtitle}
            </p>
            <div className="grid lg:grid-cols-2 font-mont py-8 px-8">
              {services.map((service, index) => (
                <CarouselCard key={index} service={service} />
              ))}
            </div>
            <div className='px-8 text-mont sm:text-lg'>

              <p className='text-start text-vino'>Jacuzzi:</p>
              <ul className="list-disc pl-10 text-start">
                <li>            {languageData.spa?.card?.age} </li>
                <li>            {languageData.spa?.card?.shift}</li>
              </ul>
              <p className='text-start text-vino'>{languageData.spa?.card?.shower}</p>
              <ul className="list-disc pl-10 text-start">
                <li>{languageData.spa?.card?.shift} </li>

              </ul>
              <p className='text-start text-vino'>{languageData.spa?.card?.massages}</p>
              <ul className="list-disc pl-10 text-start">
                <li>{languageData.spa?.card?.reservation}   </li>
                <li>{languageData.spa?.card?.prices} </li>
              </ul>
              <p className="  font-mont text-base   ">
                {languageData.spa?.card?.schedule}
              </p>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

const CarouselCard = ({ service }) => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % service.imgs.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [service.imgs.length]);

  return (
    <div className="container-card-room sm:p-2 pb-4">
      <div className="relative w-full h-80  rounded-lg mb-4">
        {service.imgs.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`${service.type} ${index + 1}`}
            className={`absolute w-full h-[400px] object-cover  ${index === currentImage ? 'opacity-100' : 'opacity-0'}`}
          />
        ))}
      </div>
      <div className="relative w-4/5 ml-[10%] z-50 bg-white p-4 shadow-md  text-center">
        <h3 className="text-vino text-lg font-mont ">{service.type}</h3>
        <p className="text-gray-600 font-mont">{service.detail}</p>

      </div>


    </div>
  );
};
