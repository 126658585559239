/*import { useIsWeb } from "../hooks/isWeb";*/

import { useFirebase, useLanguage } from '../../../hooks/useContexts';
import single from '../../../assets/Habitaciones.jpg'
import { useEffect } from 'react';

export default function RoomCard() {
  const { languageData } = useLanguage()
  const { analytics, logEvent } = useFirebase();

  useEffect(() => {
    if (analytics) {
      logEvent(analytics, "view_rooms_section", {
        section: "Rooms Card",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-crema">
      <div className="flex justify-center items-center bg-crema p-8">
        <div className="flex flex-col md:flex-row bg-white py-5 px-5 rounded items-start md:items-center w-full max-w-6xl">

          {/* Imagen de la habitación */}
          <div className="w-full md:w-1/2 order-1 md:order-1 mt-6 md:mt-0">
            <div className="relative">
              <img
                src={single}
                alt="Habitaciones de Hotel en Purmamarca, El Manantial del Silencio"
                className="rounded-lg shadow-lg"
              />
            </div>
          </div>

          {/* Información de la habitación */}
          <div className="w-full md:w-1/2 order-2 md:order-1 px-4">
            <h2 className="text-4xl font-libre-baskerville italic text-vino mb-2 sm:mx-4 pt-4">
              {languageData?.home?.cards?.room?.title}
            </h2>
            <p className="text-xl font-mont text-zinc-500 mb-4 sm:mx-4">
              {languageData?.home?.cards?.room?.subTitle}
            </p>
            <p className="text-zinc-700 font-mont mb-6 sm:mx-4">
              {languageData?.home?.cards?.room?.text}
            </p>
            <a
              href="/rooms"
              className="font-semibold mt-2 sm:mx-4"
              onClick={() => {
                if (analytics) {
                  logEvent(analytics, "click_see_more", {
                    section: "Rooms Card",
                    button_text: languageData?.home?.cards?.room?.seeMore,
                  });
                }
              }}
            >
              {languageData?.home?.cards?.room?.seeMore}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};