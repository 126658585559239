import Hero from "../../components/Hero";
import { useFirebase, useLanguage } from "../../hooks/useContexts";
import double from "../../assets/doble.webp";
import { useEffect } from "react";
import DetailRoom from "../Room/components/DetailRoom";

export default function DobleRoom() {
  const title = "Double Room";
  const { languageData } = useLanguage()
  const { analytics, logEvent } = useFirebase();
  useEffect(() => {
    document.title = "Double Room - El Manantial del Silencio";
    logEvent(analytics, "page_view", {
      page_title: "Double Room - El Manantial del Silencio",
      page_path: window.location.pathname,
      page_location: window.location.host
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Hero imgUrl={double} returnPage={languageData?.routes.rooms} hideTitle={true} />

      <DetailRoom title={title} />
    </>
  );
}