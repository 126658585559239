import Hero from "../../components/Hero";
import single from '../../assets/Spa.jpg'
import Spa from "./components/Spa";
import { useEffect } from "react";
import { useFirebase, useLanguage } from "../../hooks/useContexts";


export default function SpaPage() {
    const { languageData } = useLanguage();
    const { analytics, logEvent } = useFirebase();

    useEffect(() => {
        document.title = languageData?.spa?.metadata?.pageTitle;
        logEvent(analytics, "page_view", {
            page_title: languageData?.spa?.metadata?.pageTitle,
            page_path: languageData?.spa?.metadata?.path,
            page_location: window.location.host
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Hero imgUrl={single} hideTitle={true} />
            <Spa />
        </>
    )
}