import React, { useEffect } from "react";
import { useFirebase, useLanguage } from '../../../hooks/useContexts';

const EventsForm = () => {
  const { languageData } = useLanguage();
  const { analytics, logEvent } = useFirebase();
  useEffect(() => {
    if (analytics) {
      logEvent(analytics, "view_form_event", {
        section: "Event Form",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    if (analytics) {
      logEvent(analytics, "form_submit", {
        form_name: "Event Reservation Form",
      });
    }
  };

  return (
    <form
      className="w-full max-w-lg"
      action="https://formsubmit.co/manzurmariaflorencia@gmail.com"
      method="POST"
    >
      <div className="flex flex-wrap -mx-3 ">
        <h2 className="text-4xl font-libre-baskerville italic text-vino mb-2 sm:mx-4">
          {languageData?.home?.eventForm?.title}
        </h2>
        <div className="w-full md:w-1/2 px-3  md:mb-0">
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            id="grid-first-name"
            name="nombre"
            type="text"
            placeholder={languageData?.home?.eventForm?.namePlaceholder}
          />
        </div>
        <div className="w-full md:w-1/2 px-3">
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-last-name"
            name="apellido"
            type="text"
            placeholder={languageData?.home?.eventForm?.lastNamePlaceholder}
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 ">
        <div className="w-full px-3">
          <label className="block uppercase tracking-wide text-white text-xs font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="email"
            name="email"
            type="email"
            placeholder={languageData?.home?.eventForm?.emailPlaceholder}
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          <textarea
            className="no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
            name="message"
            id="message"
            placeholder={languageData?.home?.eventForm?.messagePlaceholder}
          ></textarea>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <button
          className="bg-vino py-4 px-4 font-bold text-white"
          onClick={handleClick}
          type="submit"
        >
          {languageData?.home?.eventForm?.send}
        </button>
        <a
          href="/events"
          className="text-vino pr-4 text-lg underline"
          onClick={() => {
            if (analytics) {
              logEvent(analytics, "click_see_more_events", {
                section: "Event Form",
              });
            }
          }}
        >
          {languageData?.home?.eventForm?.seeMore}
        </a>
      </div>

      <input type="hidden" name="_next" value="https://randev.com.ar/"></input>
      <input type="hidden" name="_captcha" value="false"></input>
    </form>
  );
};

export default EventsForm;