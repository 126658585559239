
import DetailRoom from "../Room/components/DetailRoom";
import Hero from "../../components/Hero";
import { useLanguage, useFirebase } from "../../hooks/useContexts";
import casa from "../../assets/casa.webp";
import { useEffect } from "react";
export default function EjectSuiteRoom() {
  const title = "House"
  const { languageData } = useLanguage()
  const { analytics, logEvent } = useFirebase();
  useEffect(() => {
    document.title = "House - El Manantial del Silencio";
    logEvent(analytics, "page_view", {
      page_title: "House - El Manantial del Silencio",
      page_path: window.location.pathname,
      page_location: window.location.host
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Hero imgUrl={casa} returnPage={languageData?.routes.rooms} hideTitle={true} />

      <DetailRoom title={title} />
    </>
  );
}

