/*import { useIsWeb } from "../hooks/isWeb";*/
import { useFirebase, useLanguage } from "../../../hooks/useContexts";
import calandria from '../../../assets/logo-calandria.png'
import fotocomida from '../../../assets/Calandria.jpg'
import { useEffect } from "react";

export default function RestaurantCard() {
  /* let isWeb = useIsWeb(); */
  const { languageData } = useLanguage()
  const { analytics, logEvent } = useFirebase();
  useEffect(() => {
    if (analytics) {
      logEvent(analytics, "view_restaurant_section", {
        section: "Restaurant Card",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="bg-crema">
      <div className="flex justify-center items-center bg-crema p-8">
        <div className="flex flex-col md:flex-row bg-white py-5 px-5 rounded items-start md:items-center w-full max-w-6xl">
          <div className="w-full md:w-1/2 order-2 md:order-1">
            <img
              src={calandria}
              className="w-[200px] mb-2 mx-4 pt-4"
              alt="Calandria cocina Gourmet, Purmamarca"
            />
            <p className="text-xl font-mont text-zinc-500 mb-4 mx-4">
              {languageData?.home?.cards?.restaurant?.subTitle}
            </p>
            <p className="text-zinc-700 font-mont mb-6 mx-4">
              {languageData?.home?.cards?.restaurant?.text}
            </p>
            <a
              href="/resto"
              className="font-semibold uppercase mt-2 mx-4"
              onClick={() => {
                if (analytics) {
                  logEvent(analytics, "click_see_more", {
                    section: "Restaurant Card",
                    button_text: languageData?.home?.cards?.restaurant?.seeMore,
                  });
                }
              }}
            >
              {languageData?.home?.cards?.restaurant?.seeMore}
            </a>
          </div>
          <div className="w-full md:w-1/2 flex justify-center md:justify-start order-1 md:order-2 mt-6 md:mt-0">
            <div className="relative">
              <img
                src={fotocomida}
                alt="restaurante en Purmamarca, Jujuy"
                className="rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
