import { useEffect } from "react";
import { useIsWeb } from "../../../hooks/isWeb";
import { useLanguage } from "../../../hooks/useContexts";
import { useFirebase } from "../../../hooks/useContexts";
import ConstructionModal from './ContainerModal';

export default function AboutResto() {
  const { languageData } = useLanguage();
  const isWeb = useIsWeb();
  const { analytics, logEvent } = useFirebase();

  useEffect(() => {
    if (analytics) {
      logEvent(analytics, "view_about_resto_section", {
        section: "About Resto",
      });
    }
  }, [analytics, logEvent]);

  const handleNavigate = () => {
    if (analytics) {
      logEvent(analytics, "click_navigate_menu", {
        section: "About Resto",
        action: "User clicked to view the menu",
      });
    }
    window.location.href = 'https://odibo.app/menu/manantialdelsilencio';
  };

  return (
    <section className={`bg-crema min-h-[640px] mx-auto px-3 py-16 ${isWeb ? "min-w-[700px]" : "max-w-[700px]"}`}>
      <div className="absolute z-50 left-0 w-full flex justify-center items-center">
        <ConstructionModal
          title={languageData?.restaurant?.data?.titleSection}
          detail={languageData?.restaurant?.data?.detailSection}
          letter={languageData?.restaurant?.data?.letterSection}
          onNavigate={handleNavigate}
        />
      </div>
    </section>
  );
}
