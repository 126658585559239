import React, { useEffect, useState } from "react";
import inforooms from "../../../assets/inforooms.jpg";
import { useIsWeb } from "../../../hooks/isWeb";
import { useLanguage } from "../../../hooks/useContexts";

export default function DetailRoom({ title }) {
  let isWeb = useIsWeb();
  const { languageData } = useLanguage();

  const [pathSegment, setPathSegment] = useState("");
  const [roomData, setRoomData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Obtener el segmento de la URL
    const urlPath = window.location.pathname;
    const segments = urlPath.split("/");
    const lastSegment = segments.pop() || segments.pop(); // Maneja casos donde la URL puede terminar en una barra
    setPathSegment(lastSegment);
  }, []);

  useEffect(() => {
    // Obtener los datos de la habitación
    if (languageData && pathSegment) {
      const data = languageData?.room?.data?.[pathSegment];
      setRoomData(data);
      console.log(JSON.stringify(data, null, 2), "roomData");
    }
  }, [languageData, pathSegment]);

  const handleImageClick = () => {
    setIsModalOpen(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section>
      <div className="absolute bg-crema left-0 w-full  flex justify-center items-center">
        <div
          className={`z-50 rounded-lg bg-crema flex flex-col ${isWeb ? "mt-[-5%]" : "mt-[-40%]"
            } text-center`}
        >
          <div className="container rounded bg-white w-full max-w-[1200px] mx-auto py-16 px-6">
            <h2 className="font-libre-baskerville italic text-3xl sm:text-[46px] tracking-[.04em] text-vino text-center mb-3">
              {roomData?.title || "Default Title"}
            </h2>
            <div className="py-10 font-mont">
              <div>
                <p className="text-start font-mont mb-4">
                  {roomData?.informationRoom || "InformationRoom..."}
                </p>
                <p className="text-vino py-2 font-mont text-base sm:text-lg text-start mb-4">
                  {roomData?.moreInformation || "MoreInformation..."}
                </p>
                <div className="text-start font-mont mb-4">
                  <ul className="list-disc pl-10">
                    {roomData?.moreInformactionArry?.map((infoArray, index) => (
                      <li key={index}> {infoArray} </li>
                    )) || <li>More Information...</li>}
                  </ul>
                </div>
                <p className="text-vino py-2 font-mont text-base sm:text-lg text-start mb-4">
                  {roomData?.additionalConfiguration ||
                    "AdditionalConfiguration..."}
                </p>
              </div>
              <div className="text-start mb-4">
                <ul className="list-disc pl-10">
                  {roomData?.additional?.map((infoArray, index) => (
                    <li key={index}> {infoArray} </li>
                  )) || <li>Additional...</li>}
                </ul>
              </div>
              <p className="py-2 text-start mb-4">
                {roomData?.theLastDetail || "The Last Detail..."}
              </p>
              <div>
                <img
                  src={inforooms}
                  alt="Información de la habitación"
                  className="w-full h-auto rounded cursor-pointer"
                  onClick={handleImageClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
          <div className="relative w-full h-full flex items-center justify-center p-4">
            <button
              onClick={handleCloseModal}
              className="absolute top-4 right-4 bg-white text-black p-3 rounded-full shadow-md hover:bg-gray-300 focus:outline-none"
            >
              &times;
            </button>
            <img
              src={inforooms}
              alt="Información de la habitación"
              className="w-full h-full object-contain rounded"
            />
          </div>
        </div>
      )}
    </section>
  );
}
