import { useLanguage } from './hooks/useContexts';
import DobleRoom from './pages/DataRooms/DobleRoom';
import EjectSuiteRoom from './pages/DataRooms/EjectSuiteRoom';
import SingleRoom from './pages/DataRooms/SingleRoom';
import SuiteRoom from './pages/DataRooms/SuiteRoom';
import Events from './pages/Events/Events';
import RestaurantPage from './pages/Restaurant/RestaurantPage';
import RoomPage from './pages/Room/RoomPage';
import SpaPage from './pages/Spa/SpaPage';
import Home from './pages/home/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  const { languageData } = useLanguage()
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={languageData?.routes.homePage} element={<Home />} />
          <Route path={languageData?.routes.rooms} element={<RoomPage />} />
          <Route path={languageData?.routes.resto} element={<RestaurantPage />} />
          <Route path={languageData?.routes.spa} element={<SpaPage />} />
          <Route path={languageData?.routes.events} element={<Events />} />

          <Route path={languageData?.routes.singleRoom} element={<SingleRoom />} />
          <Route path={languageData?.routes.doubleRoom} element={<DobleRoom />} />
          <Route path={languageData?.routes.suiteRoom} element={<SuiteRoom />} />
          <Route path={languageData?.routes.ejectSuiteRoom} element={<EjectSuiteRoom />} />

        </Routes>
      </BrowserRouter>
    </>

  );
}

export default App;
