import { useEffect } from "react";
import { useFirebase, useLanguage } from "../hooks/useContexts";
import logofooter from "../assets/logofooterman.png";
import logoaht from "../assets/logoaht.png";
import bur from "../assets/bureau.png";
import si from "../assets/siquiero.png";

export default function Footer() {
    const { languageData } = useLanguage();
    const { analytics, logEvent } = useFirebase();

    useEffect(() => {
        if (analytics) {
            logEvent(analytics, "view_footer_section", {
                section: "Footer",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLinkClick = (linkName) => {
        if (analytics) {
            logEvent(analytics, "click_footer_link", {
                section: "Footer",
                link_name: linkName,
            });
        }
    };

    return (
        <footer>
            <div className="bg-crema py-16 px-3">
                <div className="grid max-w-[1200px] mx-auto gap-8 text-center md:grid-cols-2 md:text-start lg:grid-cols-3">

                    {/* Logo y certificaciones */}
                    <div>
                        <div className="flex justify-center items-center">
                            <img
                                src={logofooter}
                                className="h-[140px]"
                                alt="Manantial del Silencio, Purmamarca"
                            />
                        </div>
                        <div className="flex pt-4 justify-center items-center">
                            <a
                                href="https://www.ahtra.com.ar/jujuy"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => handleLinkClick("AHTRA")}
                            >
                                <img src={logoaht} className="h-[40px]" alt="Logo de AHTRA" />
                            </a>
                            <img
                                src={bur}
                                className="h-[40px] pl-3"
                                alt="Jujuy Bureau, Purmamarca"
                            />
                            <img
                                src={si}
                                className="h-[40px] pl-3"
                                alt="Jujuy, Si Quiero - Casamientos Jujuy, Purmamarca"
                            />
                        </div>
                    </div>

                    {/* Información de contacto */}
                    <div>
                        <h4 className="inline-block font-gilda tracking-[.04em] text-lg text-vino capitalize relative after:absolute after:content-[''] after:left-0 after:-bottom-0 after:h-[1px] after:w-full after:bg-vino pb-1 mb-4">
                            {languageData.footer.contacto}
                        </h4>
                        <ul className="font-mont">
                            <li className="my-2 justify-center md:justify-start">
                                <span className="text-vino/80 font-light">
                                    {languageData.footer.name}
                                </span>
                            </li>
                            <li className="my-2 justify-center md:justify-start">
                                <span className="text-vino/80 font-light">
                                    {languageData.footer.number}
                                </span>
                            </li>
                            <li className="my-2 justify-center md:justify-start">
                                <span className="text-vino/80 font-light">
                                    {languageData.footer.email}
                                </span>
                            </li>
                            <li className="my-2 justify-center md:justify-start">
                                <span className="text-vino/80 font-light">
                                    {languageData.footer.route}
                                </span>
                            </li>
                        </ul>
                    </div>

                    {/* Enlaces de navegación */}
                    <div>
                        <h4 className="inline-block font-gilda tracking-[.04em] text-lg text-vino capitalize relative after:absolute after:content-[''] after:left-0 after:-bottom-0 after:h-[1px] after:w-full after:bg-vino pb-1 mb-4">
                            {languageData.footer.explorar}
                        </h4>
                        <ul className="font-mont">
                            <li className="my-2 justify-center md:justify-start">
                                <a
                                    href="/resto"
                                    className="text-vino/80 font-light"
                                    onClick={() => handleLinkClick("Calandria")}
                                >
                                    {languageData.footer.calandria}
                                </a>
                            </li>
                            <li className="my-2 justify-center md:justify-start">
                                <a
                                    href="/rooms"
                                    className="text-vino/80 font-light"
                                    onClick={() => handleLinkClick("Habitaciones")}
                                >
                                    {languageData.footer.habitaciones}
                                </a>
                            </li>
                            <li className="my-2 justify-center md:justify-start">
                                <a
                                    href="/events"
                                    className="text-vino/80 font-light"
                                    onClick={() => handleLinkClick("Eventos")}
                                >
                                    {languageData.footer.eventos}
                                </a>
                            </li>
                            <li className="my-2 justify-center md:justify-start">
                                <a
                                    href="/Spa"
                                    className="text-vino/80 font-light"
                                    onClick={() => handleLinkClick("Spa")}
                                >
                                    {languageData.footer.spa}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Créditos */}
            <div className="bg-[#bebbae] justify-center space-x-4 text-white py-2 text-center">
                <div className="py-">
                    <p className="text-sm text-vino">
                        Made with <span className="text-red-500">❤️</span> by{" "}
                        <a
                            href="https://www.randev.com.ar"
                            className="font-bold italic uppercase"
                            onClick={() => handleLinkClick("Randev")}
                        >
                            <span className="text-primary"> ran</span>
                            <span className="text-secondary">dev</span>
                        </a>
                    </p>
                </div>
            </div>
        </footer>
    );
}
