import Hero from "../../components/Hero";
import Rooms from "./components/Rooms";
import { useEffect } from "react";
import { useFirebase, useLanguage } from "../../hooks/useContexts";
export default function RoomPage() {
    const { languageData } = useLanguage();
    const { analytics, logEvent } = useFirebase();

    useEffect(() => {
        document.title = languageData?.room?.metadata?.pageTitle;
        logEvent(analytics, "page_view", {
            page_title: languageData?.room?.metadata?.pageTitle,
            page_path: languageData?.room?.metadata?.path,
            page_location: window.location.host
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Hero imgUrl={'https://firebasestorage.googleapis.com/v0/b/manantial-del-silencio.firebasestorage.app/o/public%2Fpages%2Frooms%2Fhero%2Froom.jpg?alt=media&token=4d18ccea-07d8-4d5d-b0fc-34629dc0f692'} hideTitle={true} />
            <Rooms isCardInfo={true} />
        </>
    )
}